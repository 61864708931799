div.radio-container {
  margin: auto;
  /* Width manually overriden depending on window width */
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border: solid;
  margin-top: 8px;
  border-radius: 6px;
  border-width: 2px;
  border-color: slategray;
  padding: 4px;
  padding-left: 12px;
  background-color: aliceblue;
}

.snug {
  margin-top: 4px;
  margin-bottom: 4px;
}
