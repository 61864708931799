p.inner {
  background-color: tomato;
};

div.section {
  text-align: left;
  align-items: left;
  border-style: solid;
  border-radius: 8px;
  border-width: 2px;
};

.centered {
  height: 10vh; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-centered {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 8px;
}

p.review {
  width: 40%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin: 8px;
}