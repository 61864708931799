.App-header {
  background-color: #282c34;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h2.App-header {
  min-height: auto;
  margin-top: 1vmin;
  margin-bottom: 1vmin;
}

p.App-header {
  min-height: auto;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: calc(5px + 2vmin);
}

/* middle */
div.categories-container {
  margin-bottom: 8px;
}

/* footer */
.App-footer {
  background-color: #1e2127;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: calc(3px + 1vmin);
  color: white;
}

p.signature {
  margin: 16px;
  font-size: small;
}
